<template>
<v-card flat max-height="65vh" class="scroll-card">
    <v-card-text class="py-0">
        <v-list flat class="px-0" v-if="datos.length" active-class="primary--text">
            <v-list-item class="rounded-lg px-1" v-for="(servicio, index) in datos" :key="index">
                <v-list-item-avatar class="mr-2" :color="ServicioActivo(servicio.inicio).avatar" size="30">
                    <v-icon :color="ServicioActivo(servicio.inicio).icon" size="20">
                        {{ServicioIcono(servicio.dir)}}
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                    <v-list-item-title class="my-0 text-subtitle-2 grey--text text-capitalize d-flex align-center" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                        <div>
                            <span class="" :class="TemaDark?'grey--text text--lighten-2':'grey--text'">{{servicio.turno}}</span> - 
                        </div>
                        <div class="ml-1">
                            <p v-for="(maquinista, index) in servicio.maquinista" :key="index" class="mb-0" >{{maquinista}}</p>
                        </div>
                    </v-list-item-title>
                    <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium grey--text text--darken-1">
                        <v-list-item-subtitle class="font-weight-regular">{{FormatRecorrido(servicio.dir)}}</v-list-item-subtitle>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-3">
                    <span class="grey--text text-subtitle-2">{{servicio.inicio}} - {{servicio.termino}}</span>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card-text>
</v-card>
</template>

<script>
import {
    mapState,
} from "vuex";
export default {
    name: 'Recorrido',
    props: ['datos', 'inicio'],
    data() {
        return {}
    },
    computed:{
        ...mapState(['dialog', 'usuario','sistema']),
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        }
    },
    methods: {
        ServicioIcono(direccion) {
            let icono = null
            switch (direccion) {
                case 'pu-li':
                    icono = 'mdi-arrow-up'
                    break
                case 'li-pu':
                    icono = 'mdi-arrow-down'
                    break
                case 'pu-sa':
                    icono = 'mdi-arrow-up'
                    break
                case 'sa-pu':
                    icono = 'mdi-arrow-down'
                    break
                case 'pu-eb':
                    icono = 'mdi-arrow-up'
                    break
                case 'eb-pu':
                    icono = 'mdi-arrow-down'
                    break
                case 'sa-mi':
                    icono = 'mdi-arrow-down'
                    break
                case 'po-sa':
                    icono = 'mdi-arrow-up'
                    break
                case 'li-ba':
                    icono = 'mdi-arrow-down'
                    break
            }
            return icono
        },
        ServicioActivo(inicio) {
            let color = {}
            if(this.sistema.tema == 'dark'){
                color = {
                    avatar: 'transparent',
                    icon: 'grey lighten-3'
                }
                if (this.inicio == inicio) {
                    color.icon = 'grey lighten-4'
                    color.avatar = 'grey darken-3'
                }
            }else{
                color = {
                    avatar: 'transparent',
                    icon: 'grey'
                }
                if (this.inicio == inicio) {
                    color.icon = 'primary'
                    color.avatar = 'secondary'
                }
            }
            return color
        },
        FormatRecorrido(recorrido) {
            let nombre_recorrido
            switch (recorrido) {
                case 'pu-li':
                    nombre_recorrido = 'Puerto - Limache'
                    break
                case 'li-pu':
                    nombre_recorrido = 'Limache - Puerto'
                    break
                case 'pu-sa':
                    nombre_recorrido = 'Puerto - S.Aldea'
                    break
                case 'sa-pu':
                    nombre_recorrido = 'S.Aldea - Puerto'
                    break
                case 'eb-pu':
                    nombre_recorrido = 'El Belloto - Puerto'
                    break
                case 'pu-eb':
                    nombre_recorrido = 'Puerto - El Belloto'
                    break
                case 'sa-mi':
                    nombre_recorrido = 'S.Aldea - Miramar'
                    break
                case 'po-sa':
                    nombre_recorrido = 'Portales - S.Aldea'
                    break
                case 'li-ba':
                    nombre_recorrido = 'Limache - Baron'
                    break
                default:
                    break;
            }
            return nombre_recorrido
        }
    }
}
</script>

<style lang="scss" scoped>
.lista-turno {
    margin: 10px 0;
    min-width: 20px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 15px;
}

.scroll-card{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}
</style>
